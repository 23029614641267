$fullhd: 1920px;
$wsxg: 1680px;
$laptops: 1366px;
$laptops-sm: 1280px;
$tablets: 1024px;
$lg: 960px;
$md: 768px;
$sm: 650px;
$xs: 480px;
$smallest: 375px;

@mixin fullhd {
	@media screen and (max-width: $fullhd) {
		@content;
	}
}

@mixin wsxg {
	@media screen and (max-width: $wsxg) {
		@content;
	}
}

@mixin laptops {
	@media screen and (max-width: $laptops) {
		@content;
	}
}

@mixin laptops-sm {
	@media screen and (max-width: $laptops-sm) {
		@content;
	}
}

@mixin tablets {
	@media screen and (max-width: $tablets) {
		@content;
	}
}

@mixin lg {
	@media screen and (max-width: $lg) {
		@content;
	}
}

@mixin md {
	@media screen and (max-width: $md) {
		@content;
	}
}

@mixin sm {
	@media screen and (max-width: $sm) {
		@content;
	}
}

@mixin xs {
	@media screen and (max-width: $xs) {
		@content;
	}
}

@mixin smallest {
	@media screen and (max-width: $smallest) {
		@content;
	}
}