@import './config.scss';

// main page


.welcome_section {
	padding-top: 80px;
	padding-bottom: 20px; 

	@include wsxg {
		padding: 80px 0;
	}
	@include md {
		padding: 50px 0;
	}
	@include sm {
		padding: 40px 0;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		


		@include lg {
			// flex-direction: column-reverse;

		}

		.text_block {
			
			padding-right: 50px;

			@include laptops {
				padding-right: 25px;
			}
			@include lg {
				width: 80%;
				padding-right: 0;
			}
			@include md {
				width: 100%;
			}

			.logo {
				// width: 100px;
				height: 37px;
				margin-bottom: 20px;

				// @include tablets {
				// 	width: 80px;
				// 	margin-bottom: 15px;
				// }
				// @include sm {
				// 	width: 60px;
				// 	margin-bottom: 5px;
				// }

				svg {
					fill: #26ACB4;
				}
			}
			.title {
				margin-bottom: 40px;

				@include tablets {
					margin-bottom: 25px;
				}
				@include sm {
					margin-bottom: 15px;
				}
			}
			.props_list {
				margin-bottom: 50px;

				@include tablets {
					margin-bottom: 25px;
				}
			}
			.buttons {
				display: flex;
				align-items: flex-start;
				flex-wrap: wrap;

				@include xs {
					justify-content: center;
					display: flex;
    				flex-direction: column;
    				align-items: center;
    				flex-wrap: wrap;
				}

				@include smallest {
					justify-content: center;
					display: flex;
    				flex-direction: column;
    				align-items: center;
    				flex-wrap: wrap;
				}

				.button_go {
					margin-right: 25px;

					@include laptops {
						margin-right: 10px;
					}
					@include tablets {
						// margin-right: 0;
						margin-bottom: 20px;
					}

					@include xs {
						margin-right: 0;
					}

					@include smallest {
						margin-right: 0;
					}

					&:last-child {
						margin-right: 0;
						margin-bottom: 0;
					}
				}
				.button_go__fill {

				}
			}
		}
		.image_block {
					
			display: flex;
			justify-content: flex-end;
			align-items: center;

			@include lg {
				width: 80%;
				justify-content: center;
				margin-bottom: 30px;
			}
			@include md {
				width: 100%;
			}

			img {
				@include lg {
					width: 350px;
				}
			}
		}
	}
}




.welcom_section_new {

	display: flex;

	@include lg {
		display: block;
			
	}
	

	.wrapper {
		height: auto;
		display: flex;
		
		width: 50%;

		@include lg {
		width: 100%;
		margin: 0 auto;	
		}
		
	}
	
	.wrapper_brillia {
		background-color: #ECF6F6;
		padding: 80px 100px;

		@include fullhd {
			padding: 100px 100px
		}

		@include wsxg {
			padding: 100px 80px;
		}

		@include laptops-sm {
			padding: 50px 40px;
		}

		@include md {
			padding: 50px 30px;
		}
		@include sm {
			padding: 40px 25px;
		}

		.image_block{
			width: 80%;

			@include lg {
				width: 100%;
			}
		}
		.text_block {
			padding: 0; 
			margin-top: 150px;

			@include lg {
				margin-top: 50px;
			}

			@include sm {
				margin-top: 35px;
			}

			@include smallest {
				margin-top: 30px;
			}
		}
		.t_title {
			margin-top: 30px;
			max-width: 450px;
		}
		.props_list {
			display: flex;
    		flex-direction: row;
    		width: 480px;
    		flex-wrap: wrap;
    		flex-flow: wrap-reverse;
    		margin-bottom: 30px;

    		@include laptops-sm {
    			flex-direction: column;

    		}
    	 	
			
		}
		.check {
			margin-right: 10px;
			
		}
		
	}


	.wrapper_brilliahealth {
		background-color: #FAFAFA;
		padding: 80px 100px;

		@include fullhd {
			padding: 100px 100px
		}

		@include wsxg {
			padding: 100px 80px;
		}

		@include laptops-sm {
			padding: 50px 40px;
		}

		@include md {
			padding: 50px 30px;
		}
		@include sm {
			padding: 40px 25px;
		}

		.wrapper {
			height: auto;
			display: block;
			
			width: 50%;
		}

		.image_block {		
			max-width: 100%;

			@include lg {
				width: 110%;
			}
		}
		.text_block {
			max-width: 595px;
			padding-right: 0;

			.logo {
				max-width: 100%;
				height: 41px;
				margin-top: 81px;
				margin-bottom: 25px;

				@include smallest {
				margin-top: 0;
				}
			}
			.t_title {
			max-width: 350px;
			margin-bottom: 20px;
			}

			.props_list {
				display: flex;
    			flex-direction: row;
    			max-width: 480px;
    			flex-wrap: wrap;
    			flex-flow: wrap-reverse;
    			margin-bottom: 30px;

    			@include laptops-sm {
    			flex-direction: column;
    			
    			}
			}
		}

		

		.text {
			max-width: 400px;
			font-family: Gilroy;
			font-weight: 300;
			font-size: 20px;
			line-height: 130%;
			letter-spacing: 0.02em;
			color: #194D80;
			margin-bottom: 40px;

			@include xs {
    			font-size: 14px;
    		}
		}
	}
}
/*
.brillia_health_section {
	padding: 165px 0;
	background: url('../../images/pattern.png') center, #ECF6F6;
	background-size: 100% auto;

	@include wsxg {
		padding: 145px 0;
	}
	@include tablets {
		padding: 100px 0;
	}
	@include md {
		padding: 50px 0;
	}

	.wrapper {
		display: flex;
		align-items: center;

		@include lg {
			flex-direction: column;
		}

		.image_block {
			width: 50%;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			@include lg {
				width: 80%;
				justify-content: center;
				margin-bottom: 30px;
			}
			@include md {
				width: 100%;
			}

			img {
				@include lg {
					width: 350px;
				}
			}
		}
		.text_block {
			width: 50%;
			padding-left: 50px;

			@include laptops {
				padding-left: 20px;
			}
			@include lg {
				width: 80%;
				padding-left: 0;
			}
			@include md {
				width: 100%;
			}

			.logo {
				width: 272px;
				margin-bottom: 30px;
				max-width: 40%;

				@include laptops {
					margin-bottom: 20px;
				}
				@include tablets {
					margin-bottom: 10px;
				}

				svg {
					fill: #26ACB4;
				}
			}
			.title {
				margin-bottom: 15px;
			}
			.text {
				font-weight: 500;
				font-size: 24px;
				line-height: 120%;
				letter-spacing: 0.02em;
				color: #194D80;
				margin-bottom: 40px;

				@include laptops {
					font-size: 22px;
					margin-bottom: 20px;
				}
			}
			.props_list {
				margin-bottom: 50px;

				@include tablets {
					margin-bottom: 25px;
				}
			}
			.buttons {
				display: flex;
				align-items: flex-start;
				flex-wrap: wrap;

				.button_go {
					margin-right: 25px;

					@include laptops {
						margin-right: 10px;
					}
					@include lg {
						margin-bottom: 20px;
					}

					&:last-child {
						margin-right: 0;
						margin-bottom: 0;
					}
				}
				.button_go__fill {

				}
			}
		}
	}
}
*/

.links_section {
	padding: 150px 0;

	@include wsxg {
		padding: 120px 0;
	}
	@include laptops-sm {
		padding: 100px 0;
	}
	@include md {
		padding: 50px 0;
	}

	.wrapper {
		.big_links {
			width: 100%;
			display: flex;
			justify-content: space-between;

			@include md {
				flex-direction: column;
			}

			.link {
				width: calc(50% - 25px);
				padding: 150px 70px 70px;
				border: 2px solid #ECF6F6;
				border-radius: 20px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				@include laptops {
					padding: 90px 40px 70px;
				}
				@include lg {
					width: calc(50% - 10px);
					padding: 20px;
				}
				@include md {
					width: 100%;

					&:first-child {
						margin-bottom: 20px;
					}
				}

				.image {
					width: 150px;
					height: 150px;
					margin-bottom: 50px;

					@include lg {
						width: 115px;
						height: 115px;
						margin-bottom: 20px;
					}
					@include sm {
						width: 100px;
						height: 100px;
					}
				}
				.title {
					margin-bottom: 15px;

					@include lg {
						margin-bottom: 10px;
					}
				}
				.text {
					font-size: 20px;
					line-height: 120%;
					text-align: center;
					letter-spacing: 0.02em;
					color: #194D80;
					margin-bottom: 50px;

					@include lg {
						font-size: 18px;
						margin-bottom: 20px;
					}
					@include sm {
						font-size: 16px;
					}
				}
				.button_go {

				}
			}
		}
	}
}




// Quality
.quality_control_1 {
	padding: 120px 0 213px;

	@include wsxg {
		padding: 60px 0 120px;
	}
	@include laptops-sm {
		padding: 50px 0 90px;
	}
	@include xs {
		padding: 40px 0 50px;
	}

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media screen and (max-width: 850px) {
			flex-direction: column-reverse;
		}

		.text_block {
			width: calc(100% - 500px);
			max-width: 800px;
			padding-right: 100px;

			@include laptops {
				width: 64%;
				padding-right: 50px;
			}

			@media screen and (max-width: 850px) {
				width: 100%;
				padding-right: 0;
			}

			.title {
				margin-bottom: 15px;
			}
			.text {
				p {
					font-size: 20px;
					line-height: 130%;
					letter-spacing: 0.02em;
					color: #194D80;
					margin-bottom: 15px;

					@include laptops-sm {
						font-size: 18px;
						margin-bottom: 11px;
					}
				}
			}
		}
		.image_block {
			width: 500px;
			flex-shrink: 0;

			@include laptops {
				width: 36%;
			}

			@media screen and (max-width: 850px) {
				margin-bottom: 30px;
				width: 280px;
			}

			@include xs {
				width: 200px;
			}
		}
	}
}
.quality_control_2 {
	padding: 150px 0;
	background: #FAFAFA;

	@include wsxg {
		padding: 120px 0;
	}
	@include laptops-sm {
		padding: 100px 0;
	}
	@include md {
		padding: 50px 0;
	}

	.wrapper {
		.title {
			margin-bottom: 70px;

			@include laptops {
				margin-bottom: 50px;
			}
			@include sm {
				margin-bottom: 30px;
			}
		}
		.performed_by {
			display: flex;
			justify-content: space-between;

			@include tablets {
				flex-direction: column;
			}

			.element {
				width: calc(50% - 95px);

				@include wsxg {
					width: calc(50% - 50px);
				}
				@include laptops {
					width: calc(50% - 25px);
				}
				@include tablets {
					width: 100%;

					&:first-child {
						margin-bottom: 30px;
					}
				}

				.image {
					margin-bottom: 25px;

					@include tablets {
						margin-bottom: 15px;
					}
				}
				.elem_title {
					font-weight: 600;
					font-size: 24px;
					line-height: 120%;
					letter-spacing: 0.02em;
					text-transform: uppercase;
					color: #194D80;
					margin-bottom: 10px;

					@include tablets {
						font-size: 22px;
					}
				}
				.elem_text {
					font-weight: 500;
					font-size: 18px;
					line-height: 130%;
					letter-spacing: 0.02em;
					color: #194D80;

					@include tablets {
						font-size: 16px;
					}
				}
			}
		}
	}
}



// Manufactory
.manufacture_1 {
	padding: 90px 0 160px;

	@include wsxg {
		padding: 60px 0 120px;
	}
	@include laptops-sm {
		padding: 50px 0 90px;
	}
	@include xs {
		padding: 40px 0 50px;
	}
	@include xs {
		padding: 30px 0;
	}

	.wrapper {
		display: flex;
		align-items: center;

		@include lg {
			flex-direction: column-reverse;
		}

		.text_block {
			margin-left: calc((100% - 1400px) / 2);
			padding-right: 100px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			@include wsxg {
				margin-left: calc((100% - 1270px) / 2);
			}
			@include laptops {
				margin-left: calc((100% - 1170px) / 2);
				padding-right: 50px;
			}
			@include laptops-sm {
				margin-left: calc((100% - 970px) / 2);
				padding-right: 25px;
			}
			@include tablets {
				margin-left: 30px;
			}
			@include lg {
				width: 100%;
				padding: 0 25px;
				margin: 0;
			}
			@include sm {
				padding: 0 15px;
				// margin-left: 15px;
			}

			.title {
				margin-bottom: 15px;
			}
			.text {
				p {
					font-size: 20px;
					line-height: 130%;
					letter-spacing: 0.02em;
					color: #194D80;
					margin-bottom: 15px;

					@include laptops-sm {
						font-size: 18px;
						margin-bottom: 11px;
					}
				}
			}
			.products {
				.product {
					padding: 35px 0;
					display: flex;
					align-items: center;
					position: relative;

					.image {
						width: 75px;
						height: 75px;
						margin-right: 25px;
						flex-shrink: 0;

						@include laptops-sm {
							width: 50px;
							height: 50px;
							margin-right: 20px;
						}
						@include xs {
							margin-right: 15px;
							width: 35px;
							height: 35px;
						}
					}
					.text_side {
						.prod_title {
							font-weight: 600;
							font-size: 24px;
							line-height: 120%;
							letter-spacing: 0.02em;
							text-transform: uppercase;
							color: #194D80;
							margin-bottom: 5px;

							@include laptops-sm {
								font-size: 20px;
							}
							@include xs {
								font-size: 16px;
							}
						}
						.prod_desc {
							font-weight: 500;
							font-size: 18px;
							line-height: 130%;
							letter-spacing: 0.02em;
							color: #194D80;

							@include laptops-sm {
								font-size: 16px;
							}
							@include xs {
								font-size: 14px;
							}
						}
					}

					&:first-child {
						&:before {
							content: "";
							display: block;
							width: 100%;
							height: 1px;
							background: linear-gradient(to right, #C1E6E9, rgba(0, 0, 0, 0));
							position: absolute;
							bottom: 0;
							left: 0;
						}
					}
				}
			}
		}
		.image_block {
			flex-shrink: 0;

			@include wsxg {
				width: 50%;
			}
			@include lg {
				// padding: 0 25px;
				margin-bottom: 30px;
			}
			@include sm {
				width: 100%;
				padding: 0 15px;
			}
		}
	}
}
.manufacture_2 {
	padding: 175px 0 150px;

	@include wsxg {
		padding: 120px 0;
	}
	@include laptops-sm {
		padding: 80px 0;
	}
	@include md {
		padding: 50px 0;
	}
	@include xs {
		padding: 30px 0;
	}

	.wrapper {
		.title {
			margin-bottom: 70px;

			@include laptops {
				margin-bottom: 50px;
			}
		}
		.process {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 80px;

			@include laptops {
				margin-bottom: 50px;
			}
			@include md {
				flex-wrap: wrap;
			}

			.elem {
				@include md {
					width: 50%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					&:nth-child(1),
					&:nth-child(2) {
						margin-bottom: 30px;
					}
				}
				@include smallest {
					width: 100%;

					&:nth-child(3) {
						margin-bottom: 30px;
					}
				}

				.img {
					margin-bottom: 20px;

					@include lg {
						font-size: 16px;
						width: 60px;
						height: 60px;
						margin-bottom: 15px;
					}
				}
				.elem_title {
					font-weight: 600;
					font-size: 24px;
					line-height: 120%;
					letter-spacing: 0.02em;
					text-transform: uppercase;
					color: #194D80;

					@include laptops {
						font-size: 20px;
					}
					@include lg {
						font-size: 16px;
					}
				}
			}
		}
		.bottom_text_block {
			display: flex;

			@include sm {
				flex-wrap: wrap;
			}

			.text {
				font-size: 20px;
				line-height: 130%;
				letter-spacing: 0.02em;
				color: #194D80;
				margin-right: 75px;

				@include laptops {
					font-size: 18px;
				}
				@include tablets {
					margin-right: 30px;
				}
				@include lg {
					font-size: 16px;
				}
				@include sm {
					width: 100%;

					&:first-child {
						margin-bottom: 30px;
					}
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}
.typical_banner {
	background: #26ACB4;
	padding: 25px 0;

	.wrapper {
		display: flex;
		align-items: center;

		@include sm {
			flex-direction: column;
		}

		.image_block {
			flex-shrink: 0;

			@include wsxg {
				padding-right: 50px;
				width: 50%;
			}
			@include md {
				width: 40%;
			}
			@include sm {
				width: 75%;
				padding: 0 50px;
				margin-bottom: 25px;
			}
			@include xs {
				width: 100%;
			}

			svg {
				width: 475px;
				height: 310px;
				fill: #20BDC7;

				.antibody {
					&:nth-child(1) {
						animation: antibody 5s linear infinite;
					}
					&:nth-child(2) {
						animation: antibody 7s linear infinite;
					}
					&:nth-child(3) {
						animation: antibody 6s linear infinite;
					}
					&:nth-child(4) {
						animation: antibody 10s linear infinite;
					}
					&:nth-child(5) {
						animation: antibody 4s linear infinite;
					}
					&:nth-child(6) {
						animation: antibody 9s linear infinite;
					}
					&:nth-child(7) {
						animation: antibody 5.5s linear infinite;
					}
					&:nth-child(8) {
						animation: antibody 4.5s linear infinite;
					}
					&:nth-child(9) {
						animation: antibody 6.5s linear infinite;
					}
				}
			}
		}
		.text_block {
			padding-left: 225px;

			@include wsxg {
				padding-left: 0;
				width: 50%;
			}
			@include md {
				width: 60%;
			}
			@include sm {
				width: 100%;
			}

			.title {
				margin-bottom: 15px;
				color: white;

				@include laptops-sm {
					margin-bottom: 5px;
				}
			}
			.text {
				p {
					font-size: 20px;
					line-height: 130%;
					letter-spacing: 0.02em;
					color: #FFFFFF;

					@include laptops-sm {
						font-size: 18px;
					}
					@include lg {
						font-size: 16px;
					}
				}
			}
			.props_list {
				display: flex;
				flex-wrap: wrap;
				margin-top: 5px;

				@include laptops-sm {
					margin-top: 15px;
				}

				li {
					font-weight: 500;
					font-size: 18px;
					line-height: 1.3;
					letter-spacing: 0.01em;
					color: #fff;
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					margin-right: 30px;

					@include laptops-sm {
						font-size: 16px;
						margin-bottom: 20px;
					}
					@include lg {
						margin-bottom: 15px;
					}

					&:first-child {
						margin-right: 90px;

						@include laptops-sm {
							margin-right: 0;
						}
					}

					.check {
						width: 20px;
						height: 20px;
						background: #F49F50;
						flex-shrink: 0;
						border-radius: 50%;
						margin-right: 20px;
						display: flex;
						justify-content: center;
						align-items: center;

						@include md {
							margin-right: 10px;
						}

						&:before {
							content: "";
							display: block;
							width: 12px;
							height: 8px;
							background: url('../../images/check.png') no-repeat center;
							background-size: contain;
						}
					}						
				}
			}
		}
	}

	&__blue {
		background: #194D80;

		.wrapper {
			.image_block {
				svg {
					fill: #1F65AC;
				}
			}
		}
	}
	&__red {
		background: #7D1040;

		.wrapper {
			.image_block {
				svg {
					fill: #A41F59;
				}
			}
		}
	}
}
.typical_banner_2 {
	background: #26ACB4;
	padding: 100px 0;

	@include laptops {
		padding: 80px 0;
	}
	@include md {
		padding: 50px 0;
	}
	@include xs {
		padding: 30px 0;
	}

	.wrapper {
		display: flex;
		justify-content: space-between;

		@include md {
			flex-wrap: wrap;
		}

		.text_block {
			width: 50%;

			@include md {
				width: 100%;
			}

			.title {
				margin-bottom: 15px;
				color: white;

				@include laptops {
					margin-bottom: 10px;
				}
			}
			.text {
				p {
					font-size: 20px;
					line-height: 130%;
					letter-spacing: 0.02em;
					color: #FFFFFF;

					@include laptops {
						font-size: 18px;
					}
					@include lg {
						font-size: 16px;
					}
				}
			}

			&:first-child {
				margin-right: 200px;

				@include laptops {
					margin-right: 100px;
				}
				@include tablets {
					margin-right: 40px;
				}
				@include md {
					margin-right: 0;
					margin-bottom: 25px;
				}
			}
		}
	}

	&__blue {
		background: #194C7F;
	}
	&__red {
		background: #7D1040;
	}
}


// general blocks
.standart_block_1 {
	padding: 100px 0 188px;

	@include wsxg {
		padding: 60px 0 120px;
	}
	@include laptops-sm {
		padding: 50px 0 90px;
	}
	@include xs {
		padding: 30px 0 50px;
	}
	@include smallest {
		padding: 20px 0 50px;
	}

	.wrapper {
		display: flex;
		align-items: center;

		@include md {
			flex-direction: column-reverse;
			align-items: flex-start;
		}

		.text_block {
			width: 50%;

			@include md {
				width: 100%;				
			}

			.up_title {
				font-weight: 600;
				font-size: 42px;
				line-height: 1;
				letter-spacing: 0.02em;
				color: #26ACB4;
				margin-bottom: 17px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				flex-wrap: wrap;

				@include wsxg {
					font-size: 36px;
					margin-bottom: 15px;
				}
				@include laptops {
					font-size: 32px;
					// margin-bottom: 5px;
				}
				@include lg {
					font-size: 26px;
				}

				// .logo {
				// 	@include wsxg {
				// 		max-width: 10vw;
				// 		min-width: 70px;
				// 	}
				// 	@include wsxg {
				// 		max-width: 10vw;
				// 		min-width: 70px;
				// 	}
				// }
				span {
					// width: 10vw;
					// height: 40px;
					// background: url('./../../images/brillia-text.png') no-repeat center;
					// background-
					// background-size: contain;
				}
				div {
					height: 42px;
					margin-bottom: 10px;

					@include wsxg {
						height: 36px;
					}
					@include laptops {
						height: 32px;
					}
					@include lg {
						height: 26px;
						margin-bottom: 5px;
					}
				}
			}
			.title {
				margin-bottom: 15px;
			}
			.text {
				margin-bottom: 50px;

				@include laptops {
					margin-bottom: 30px;
				}

				p {
					font-size: 20px;
					line-height: 130%;
					letter-spacing: 0.02em;
					color: #194D80;
					margin-bottom: 15px;

					@include laptops {
						font-size: 18px;
					}
					@include xs {
						font-size: 16px;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.buttons {
				display: flex;
				align-items: center;

				.button_go {

				}
			}
		}
		.image_block {
			width: 50%;
			padding-left: 50px;

			@include laptops-sm {
				padding-left: 20px;
			}
			@include md {
				width: 60%;
				margin-bottom: 30px;
				padding-left: 0;
			}
			@include sm {
				width: 80%;
			}
			@include xs {
				width: 100%;
				margin-bottom: 10px;
			}

			.props_list {
				margin-bottom: 30px;
				display: flex;
				flex-wrap: wrap;

				@include laptops {
					margin-bottom: 10px;
				}

				li {
					font-weight: 500;
					font-size: 18px;
					line-height: 21px;
					letter-spacing: 0.01em;
					color: #194C7F;
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					margin-right: 30px;

					@include laptops {						
						font-size: 16px;

						&:nth-child(2),
						&:nth-child(3) {
							width: 45%;
						}
					}
					@include laptops-sm {
						padding-right: 10px;
						margin-bottom: 15px;
						margin-right: 0;

						&:nth-child(2),
						&:nth-child(3) {
							width: 50%;
						}
					}
					@include lg {
						width: 100%;
						margin-bottom: 10px;

						&:nth-child(1),
						&:nth-child(2),
						&:nth-child(3) {
							width: 100%;
							margin-right: 0;
						}
					}

					&:first-child {
						margin-right: 90px;

						@include lg {
							margin-right: 0;
						}
					}

					.check {
						width: 20px;
						height: 20px;
						background: #26ACB4;
						flex-shrink: 0;
						border-radius: 50%;
						margin-right: 20px;
						display: flex;
						justify-content: center;
						align-items: center;

						@include laptops-sm {
							margin-right: 10px;
						}

						&:before {
							content: "";
							display: block;
							width: 12px;
							height: 8px;
							background: url('../../images/check.png') no-repeat center;
							background-size: contain;
						}
					}						
				}
			}
		}
	}
}

.standart_block_2 {
	padding: 152px 0;
	background: #FAFAFA;

	@include wsxg {
		padding: 130px 0;
	}
	@include laptops {
		padding: 100px 0;
	}
	@include md {
		padding: 50px 0;
	}
	@include xs {
		padding: 30px 0;
	}

	.wrapper {
		display: flex;
		align-items: center;

		@include tablets {
			flex-direction: column-reverse;
		}

		.text_block {
			width: 55%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			@include tablets {
				width: 100%;
			}

			.title {
				font-weight: 600;
				font-size: 48px;
				line-height: 1;
				letter-spacing: 0.02em;
				color: #194D80;
				margin-bottom: 15px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				flex-wrap: wrap;

				@include wsxg {
					font-size: 40px;
				}
				@include laptops {
					font-size: 34px;
				}
				@include sm {
					font-size: 28px;
				}

				// img {
				// 	vertical-align: middle;

				// 	@include laptops {
				// 		width: 200px;
				// 		margin-right: 50%;
				// 		margin-bottom: 10px;
				// 	}
				// 	@include sm {
				// 		width: 150px;
				// 	}
				// }

				div {
					height: 48px;
					margin-bottom: 10px;

					@include wsxg {
						height: 40px;
					}
					@include laptops {
						height: 34px;
					}
					@include sm {
						height: 28px;
						margin-bottom: 5px;
					}
				}
			}
			.text {
				p {
					font-size: 20px;
					line-height: 130%;
					letter-spacing: 0.02em;
					color: #194D80;
					margin-bottom: 25px;

					@include laptops {
						font-size: 18px;
						margin-bottom: 15px;
					}
					@include sm {
						font-size: 16px;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}

				&.text_read_more {
					p {
						display: none;

						&:nth-child(1),
						&:nth-child(2),
						&:nth-child(3) {
							display: inline-block;
						}

						&.active {
							display: inline-block;
						}
					}
				}
			}
			.button_go {
				margin-top: 50px;

				@include tablets {
					margin-top: 25px;
				}
			}
		}
		.image_block {
			width: 45%;
			padding-left: 50px;

			@include laptops {
				padding-left: 25px;
			}
			@include tablets {
				width: 100%;
				padding-left: 0;
				margin-bottom: 30px;
			}
			@include sm {
				margin-bottom: 15px;
			}
		}
	}
}

.standart_block_3 {
	padding: 150px 0;
	background: #ECF6F6;

	@include wsxg {
		padding: 120px 0;
	}
	@include laptops {
		padding: 100px 0;
	}
	@include lg {
		padding: 80px 0;
	}
	@include md {
		padding: 50px 0;
	}
	@include xs {
		padding: 30px 0;
	}

	.wrapper {
		.up_title {
			font-weight: 600;
			font-size: 42px;
			line-height: 1;
			letter-spacing: 0.02em;
			color: #26ACB4;
			margin-bottom: 17px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex-wrap: wrap;

			@include laptops {
				font-size: 36px;
			}
			@include laptops-sm {
				font-size: 28px;
			}
			@media screen and (max-width: 580px) {
				font-size: 22px;
				margin-bottom: 5px;
			}

			// img {
			// 	@include laptops {
			// 		width: 250px;
			// 	}
			// 	@include laptops-sm {
			// 		width: 190px;
			// 	}
			// 	@media screen and (max-width: 580px) {
			// 		width: 130px;
			// 		margin-right: 50%;
			// 		margin-bottom: 10px;
			// 	}
			// }
			div {
				height: 42px;
				margin-bottom: 10px;

				@include laptops {
					height: 36px;
				}
				@include laptops-sm {
					height: 28px;
				}
				@media screen and (max-width: 580px) {
					height: 22px;
					margin-bottom: 5px;
				}
			}
		}
		.title {
			margin-bottom: 15px;
		}
		.text {
			font-weight: 500;
			font-size: 24px;
			line-height: 130%;
			letter-spacing: 0.02em;
			color: #194D80;
			margin-bottom: 80px;

			@include laptops {
				font-size: 22px;
				margin-bottom: 50px;
			}
			@include laptops-sm {
				font-size: 20px;
				margin-bottom: 25px;
			}
			@include lg {
				font-size: 18px;
			}
			@media screen and (max-width: 580px) {
				br {
					display: none;
				}
			}
		}
		.mechanism {
			display: flex;
			flex-wrap: wrap;

			.elem {
				width: 25%;
				padding: 75px;
				position: relative;

				@include laptops {
					padding: 50px 20px;
				}
				@include md {
					width: 50%;
					padding: 20px;
				}

				&:nth-child(1),
				&:nth-child(4n + 1) {
					.inner_wrapper {
						padding-left: 0;
					}
				}

				.inner_wrapper {
					padding-left: 25px;

					@include laptops {
						padding-left: 0;
					}
					@include tablets {
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						align-items: center;
					}

					.img {
						margin-bottom: 20px;
						width: 75px;
						height: 78px;
					}
					.elem_title {
						font-weight: 600;
						font-size: 24px;
						line-height: 120%;
						letter-spacing: 0.02em;
						text-transform: uppercase;
						color: #194D80;
						margin-bottom: 5px;

						@include laptops-sm {
							font-size: 20px;
						}
						@include smallest {
							font-size: 18px;
						}
					}
					.elem_text {
						font-weight: 500;
						font-size: 18px;
						line-height: 130%;
						letter-spacing: 0.02em;
						color: #194D80;

						@include laptops-sm {
							font-size: 16px;
						}
						@include tablets {
							text-align: center;
						}
						@include smallest {
							font-size: 14px;
						}
					}
				}

				@media screen and (min-width: 769px) {
					&:nth-child(1) {
						&:before {
							content: "";
							display: block;
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 2px;
							background: linear-gradient(to right, rgba(0, 0, 0, 0), #C1E6E9);
						}
					}
					&:nth-child(2),
					&:nth-child(3) {
						&:before {
							content: "";
							display: block;
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 2px;
							background: #C1E6E9;
						}
					}
					&:nth-child(4) {
						&:before {
							content: "";
							display: block;
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 2px;
							background: linear-gradient(to right, #C1E6E9, rgba(0, 0, 0, 0));
						}
					}
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						&:after {
							content: "";
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							width: 2px;
							height: 100%;
							background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #C1E6E9);

							@media screen and (max-width: 580px) {
								content: none;
							}
						}
					}
					&:nth-child(6),
					&:nth-child(7),
					&:nth-child(8) {
						&:after {
							content: "";
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							width: 2px;
							height: 100%;
							background: linear-gradient(to top, rgba(0, 0, 0, 0), #C1E6E9);

							@media screen and (max-width: 580px) {
								content: none;
							}
						}
					}
					&:nth-child(7) {
						&:before {
							content: "";
							display: block;
							position: absolute;
							top: 0;
							right: -2px;
							width: 2px;
							height: 100%;
							background: linear-gradient(to top, rgba(0, 0, 0, 0), #C1E6E9);
						}
					}
				}
				@include md {
					&:nth-child(1),
					&:nth-child(2n + 1) {
						&:before {
							content: "";
							display: block;
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 2px;
							background: linear-gradient(to right, rgba(0, 0, 0, 0), #C1E6E9);
						}
					}
					&:nth-child(2),
					&:nth-child(2n + 2) {
						&:before {
							content: "";
							display: block;
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 2px;
							background: linear-gradient(to right, #C1E6E9, rgba(0, 0, 0, 0));
						}
					}
					&:nth-child(2n + 2) {
						&:after {
							content: "";
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							width: 2px;
							height: 100%;
							background: #C1E6E9;
						}
					}
					&:nth-child(2) {
						&:after {
							content: "";
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							width: 2px;
							height: 100%;
							background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #C1E6E9);
						}
					}
					&:last-child {
						&:before {
							content: none;
						}
						&:after {
							content: "";
							display: block;
							position: absolute;
							top: 0;
							right: -2px;
							width: 2px;
							height: 100%;
							background: linear-gradient(to top, rgba(0, 0, 0, 0), #C1E6E9);
						}
					}
				}
			}

			&.mechanism_in_row {
				.elem {
					padding: 75px 0;
					padding-left: 80px;
					width: 32%;
					position: relative;

					@include laptops {
						padding-left: 50px;
					}
					@include laptops-sm {
						padding-left: 4%;
					}
					@include lg {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: flex-start;
						padding: 50px 20px;
					}
					@media screen and (max-width: 580px) {
						width: 100%;
						padding: 20px;
					}

					&:nth-child(1),
					&:nth-child(4n + 1) {
						padding-left: 0;

						@include lg {
							padding-left: 20px;
						}

						&:before {
							content: none;
						}
					}
					&:last-child {
						&:after {
							content: none;
						}
					}

					.img {
						margin-bottom: 20px;
					}
					.elem_title {
						font-weight: 600;
						font-size: 24px;
						line-height: 120%;
						letter-spacing: 0.02em;
						text-transform: uppercase;
						color: #194D80;
						margin-bottom: 5px;

						@include laptops-sm {
							font-size: 20px;
						}
					}
					.elem_text {
						font-weight: 500;
						font-size: 18px;
						line-height: 130%;
						letter-spacing: 0.02em;
						color: #194D80;

						@include laptops-sm {
							font-size: 16px;
						}
						@include lg {
							text-align: center;
						}
					}

					&:before {
						content: "";
						display: block;
						position: absolute;
						width: 1px;
						height: 100%;
						top: 0;
						left: 0;
						background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #F49F50, rgba(0, 0, 0, 0));

						@media screen and (max-width: 580px) {
							content: none;
						}
					}
					&:after {
						@media screen and (max-width: 580px) {
							content: none;
						}
					}
				}			
			}
		}
	}

	&__lightblue {
		background: #E9F2FB;

		.wrapper {
			.up_title {
				color: #F49F50;
			}
		}
	}
	&__lightred {
		background: #F8EEF2;

		.wrapper {
			.up_title {
				color: #F49F50;
			}
			.title {
				color: #7D1040;
			}
		}
	}
}

.clinical_studies {
	padding: 150px 0;

	@include wsxg {
		padding: 120px 0;
	}
	@include laptops {
		padding: 100px 0;
	}
	@include lg {
		padding: 80px 0;
	}
	@include md {
		padding: 50px 0;
	}
	@include xs {
		padding: 30px 0;
	}

	.wrapper {
		.title {
			margin-bottom: 50px;

			@include laptops {
				margin-bottom: 30px;
			}
		}
		.links_wrapper {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.block {
				padding: 100px 67px 50px;
				width: calc((100% / 3) - (110px / 3));
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				border: 2px solid #ECF6F6;
				border-radius: 10px;

				@include laptops {
					width: calc((100% / 3) - (60px / 3));
					padding: 50px 30px;
				}
				@include tablets {
					padding: 30px 20px;
					width: calc((100% / 3) - (45px / 3));
				}
				@include lg {
					padding: 20px 10px;
				}
				@include sm {
					width: 100%;
					margin-bottom: 20px;
				}

				&:nth-child(4),
				&:nth-child(5),
				&:nth-child(6) {
					margin-top: 30px;
				}

				.info {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.img {
						width: 100px;
						height: 100px;
						margin-bottom: 35px;

						@include lg {
							margin-bottom: 15px;
							width: 75px;
							height: 75px;
						}
					}
					.block_title {
						font-weight: 600;
						font-size: 24px;
						line-height: 120%;
						text-align: center;
						letter-spacing: 0.02em;
						color: #194D80;
						margin-bottom: 40px;

						@include laptops {
							font-size: 22px;
						}
						@include laptops-sm {
							font-size: 20px;
						}
						@include lg {
							margin-bottom: 20px;
							font-size: 18px;
						}
					}
				}
			}
		}
	}
}

// cold-flu and cough
.standart_block_1_color {
	padding: 90px 0 188px;

	@include wsxg {
		padding: 60px 0 120px;
	}
	@include laptops-sm {
		padding: 50px 0 90px;
	}
	@include xs {
		padding: 40px 0 50px;
	}
	@include smallest {
		padding: 20px 0 50px;
	}

	.wrapper {
		display: flex;
		align-items: center;

		@include md {
			flex-direction: column-reverse;
		}

		.text_block {
			width: 50%;

			@include md {
				width: 100%;				
			}

			.up_title {
				font-weight: 600;
				font-size: 42px;
				line-height: 1;
				letter-spacing: 0.02em;
				color: #F49F50;
				margin-bottom: 17px;

				@include laptops {
					width: 200px;
					// margin-bottom: 0;
				}
				@include xs {
					width: 150px;
				}

				div {
					height: 42px;

					@include wsxg {
						height: 36px;
					}
					@include laptops {
						height: 32px;
					}
					@include lg {
						height: 26px;
					}
				}
			}
			.title {
				margin-bottom: 15px;
			}
			.text {
				margin-bottom: 50px;

				@include laptops {
					margin-bottom: 30px;
				}

				p {
					font-size: 20px;
					line-height: 130%;
					letter-spacing: 0.02em;
					color: #194D80;
					margin-bottom: 15px;

					@include laptops {
						font-size: 18px;
					}
					@include xs {
						font-size: 16px;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.buttons {
				display: flex;
				align-items: center;

				.button_go {

				}
			}
		}
		.image_block {
			width: 50%;
			padding-left: 50px;

			@include laptops-sm {
				padding-left: 20px;
			}
			@include md {
				width: 85%;
				margin-bottom: 30px;
				padding-left: 0;
			}
			@include sm {
				width: 100%;
			}
			@include xs {
				margin-bottom: 10px;
			}

			.props_list {
				margin-bottom: 30px;
				display: flex;
				flex-wrap: wrap;

				@include laptops {
					margin-bottom: 10px;
				}

				li {
					font-weight: 500;
					font-size: 18px;
					line-height: 21px;
					letter-spacing: 0.01em;
					color: #194C7F;
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					width: 40%;

					@include laptops {
						width: 45%;
						font-size: 16px;
					}
					@include laptops-sm {
						width: 50%;
						padding-right: 10px;
						margin-bottom: 15px;
					}
					@media screen and (max-width: 420px) {
						width: 100%;
						margin-bottom: 10px;
					}

					.check {
						width: 20px;
						height: 20px;
						background: #F49F50;
						flex-shrink: 0;
						border-radius: 50%;
						margin-right: 20px;
						display: flex;
						justify-content: center;
						align-items: center;

						@include laptops-sm {
							margin-right: 10px;
						}

						&:before {
							content: "";
							display: block;
							width: 12px;
							height: 8px;
							background: url('../../images/check.png') no-repeat center;
							background-size: contain;
						}
					}						
				}
			}
		}
	}
}



// children
.brain_section {
	padding: 175px 0;

	@include wsxg {
		padding: 120px 0;
	}
	@include laptops {
		padding: 100px 0;
	}
	@include lg {
		padding: 80px 0;
	}
	@include md {
		padding: 50px 0;
	}
	@include xs {
		padding: 30px 0;
	}

	.warpper {
		display: flex;
		align-items: center;

		@include lg {
			flex-direction: column;
		}

		.control_block {
			padding-right: 30px;

			@include lg {
				width: 100%;
				padding-right: 0;
				margin-bottom: 35px;
			}

			.title {
				margin-bottom: 15px;
			}
			.text {
				font-weight: 500;
				font-size: 24px;
				line-height: 120%;
				letter-spacing: 0.02em;
				color: #194D80;
				margin-bottom: 50px;

				@include laptops-sm {
					font-size: 20px;
					margin-bottom: 30px;
				}
				@include md {
					font-size: 18px;
				}
				@include xs {
					font-size: 16px;
				}
			}
			.switcher_wrapper {
				.element {
					padding: 20px 25px;
					border-radius: 10px;
					cursor: pointer;
					background: white;
					background-clip: padding-box;
					padding: 2px;

					.inner_wrapper {
						width: 100%;
						background: #fff;
						color: white;
						padding: 20px 25px;
						border-radius: 9px;
						display: flex;
						align-items: center;

						@include lg {
							padding: 15px;
						}

						.icon {
							width: 55px;
							height: 55px;
							margin-right: 20px;
							flex-shrink: 0;

							@include laptops-sm {
								width: 45px;
								height: 45px;
							}

							svg {
								fill: #BACECF;
							}
						}
						.element_text {
							font-weight: 600;
							font-size: 18px;
							line-height: 22px;
							letter-spacing: 0.01em;
							text-transform: uppercase;
							color: #BACECF;

							@include laptops-sm {
								font-size: 16px;
							}
							@include lg {
								font-size: 14px;
							}
						}
					}

					&.active {
						&[data-color="dark-green"] {							
							background: linear-gradient(to right, #C1E6E9, rgba(255, 255, 255, 1));

							&:before {
								content: "";
								display: block;
								width: 100%;
								height: 100%;
								position: absolute;
								// background: linear-gradient(to right, red, orange);
								z-index: -1;
								top: 0;
								left: 0;
								border-radius: 10px;
							}

							.icon {
								svg {
									fill: #26ACB4;
								}
							}
							.element_text {
								color: #26ACB4;
							}
						}
						&[data-color="green"] {
							background: linear-gradient(to right, #92CC49, rgba(255, 255, 255, 1));
							
							.icon {
								svg {
									fill: #92CC49;
								}
							}
							.element_text {
								color: #92CC49;
							}
						}
						&[data-color="red"] {
							background: linear-gradient(to right, #F35F7A, rgba(255, 255, 255, 1));
							
							.icon {
								svg {
									fill: #F35F7A;
								}
							}
							.element_text {
								color: #F35F7A;
							}
						}
						&[data-color="blue"] {
							background: linear-gradient(to right, #5497D4, rgba(255, 255, 255, 1));
							
							.icon {
								svg {
									fill: #5497D4;
								}
							}
							.element_text {
								color: #5497D4;
							}
						}
					}
				}
			}
		}
		.brain {
			flex-shrink: 0;
			width: 800px;

			@include laptops {
				width: 55%;
			}
			@include lg {
				width: 60%;
			}
			@include xs {
				width: 85%;
			}

			svg {
				width: 100%;

				.small-circles {
					animation: small-circle 4s linear alternate infinite;

					&:nth-child(1), &:nth-child(3), &:nth-child(6) {
						animation: small-circle 4.4s linear alternate infinite;
					}
				}
				.middle-circles {
					animation: middle-circle 4.5s linear alternate infinite;

					&:nth-child(1), &:nth-child(3), &:nth-child(6) {
						animation: small-circle 4.1s linear alternate infinite;
					}

					&:nth-child(22){
						animation: small-circle 3.8s linear alternate infinite;
					}
				}
				.bigger-circles {
					animation: bigger-circle 3.9s linear alternate infinite;

					&:nth-child(1), &:nth-child(3), &:nth-child(6) {
						animation: small-circle 4.3s linear alternate infinite;
					}
				}
				.big-circles {
					animation: big-circle 5.5s linear alternate infinite;

					&:nth-child(1), &:nth-child(3) {
						animation: small-circle 4.5s linear alternate infinite;
					}
				}
			}

			&[data-color="dark-green"] {
				.svg {
					.circle {
						fill: #26ACB4;
					}
				}
			}
			&[data-color="green"] {
				.svg {
					.circle {
						fill: #92CC49;
					}
				}
			}
			&[data-color="red"] {
				.svg {
					.circle {
						fill: #F35F7A;
					}
				}
			}
			&[data-color="blue"] {
				.svg {
					.circle {
						fill: #5497D4;
					}
				}
			}			
		}
	}
}







// popup
.popup[data-popup="attention"] {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: none;
	justify-content: center;
	align-items: center;	
	z-index: 1010;

	@media screen and (max-width: 400px) {
		align-items: flex-end;
		padding-bottom: 20px;
	}

	&.active {
		display: flex;
	}

	.popup_close {
		width: 100%;
		height: 100%;
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		background: black;
		opacity: 0.5;
	}
	.wrapper {
		width: 1200px;
		height: calc(100% - 70px);
		border-radius: 10px;
		background: white;
		position: relative;
		z-index: 10;

		@include laptops {
			width: calc(100% - 100px);
		}
		@include md {
			width: calc(100% - 40px);
		}
		@include sm {
			width: calc(100% - 20px);
			height: calc(100% - 160px);
		}
		@media screen and (max-width: 400px) {
			height: calc(100% - 50px);
		}


		.content {
			display: block;
			width: 100%;
			height: 100%;
			overflow-y: scroll;
			border-radius: 10px;
			background: #E8F7FF;

			&::-webkit-scrollbar {
				width: 10px;				
				transition: 200ms;

				&:hover {
					width: 20px;
				}
			}
			/* Track */
			&::-webkit-scrollbar-track {
				background: #E2E7F3;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
			}
			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #194C7F;
				border-radius: 5px;
			}

			.inner_wrapper {
				padding: 50px 100px;				
				
				@include laptops {
					padding: 50px;
				}
				@include lg {
					padding: 30px;
				}
				@media screen and (max-width: 375px) {
					padding: 30px 15px;
				}
			}

			.popup-title {
				font-family: "Heebo", sans-serif;
			    font-size: 32px;
			    letter-spacing: 3%;
			    line-height: 1.25;
			    font-weight: 500;
			    color: #194C7F;
			    text-align: center;
			    margin-bottom: 40px;

				@include laptops {
					font-style: 26px;
				}
				@include md {
					font-size: 22px;
					margin-bottom: 20px;
				}
				@include smallest {
					font-size: 18px;
				}
			}

			.popup-content {
				.block_1 {
					display: flex;
					padding: 35px 0;

					@include md { // 768
						flex-direction: column;
					}

					.block-left {
						flex-shrink: 1;
						max-width: 370px;
						width: 100%;

						@include laptops { // 1280
							max-width: 295px;
						}

						@include md { // 768
							max-width: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
							margin-bottom: 20px;
						}

						img {
							width: 100%;

							@include md { // 768
								width: auto;
							}
						}
					}
					.block-right {
						flex-shrink: 10;
						font-family: "Libre Franklin", sans-serif;
						font-size: 20px;
						letter-spacing: 3%;
						line-height: 1.35;
						font-weight: 300;
						color: #194C7F;
						padding: 20px 0 20px 65px;
						display: flex;
						justify-content: center;
						align-items: center;

						@include laptops { // 1280
							font-size: 18px;
						}

						@include md { // 768
							padding: 0;
							font-size: 16px;
						}

						.text-block {

						}
					}
				}
				.block_2 {
					display: flex;
					flex-direction: column;
					padding: 35px 0;

					.block-cnt-top {
						display: flex;

						@include md { // 768
							flex-direction: column;
							align-items: center;
						}

						.block-left {
							max-width: 581px;

							@include laptops { // 1280
								max-width: 535px;
							}

							@include md { // 768
								max-width: 100%;
								width: 100%;
								display: flex;
								justify-content: center;
								align-items: center;
								margin-bottom: 10px;
							}

							img {
								width: 100%;

								@include md { // 768
									width: auto;
								}
							}
						}
						.block-right {
							display: flex;
							align-items: center;
							padding-left: 25px;

							@include md { // 768
								padding: 0;
							}
							.text-block {
								p {
									padding: 7.5px 0;
									font-family: "Libre Franklin", sans-serif;
									font-weight: 300;

									@include laptops {
										font-size: 16px;
									}

									@include md { // 768
										font-size: 12px;
									}
								}
							}
						}	
					}

					.block-cnt-bottom {
						display: flex;
						justify-content: center;
						padding-top: 20px;

						@include md { // 768
							padding-top: 5px;
						}

						.text-block {
							font-family: "Libre Franklin", sans-serif;
							font-size: 20px;
							letter-spacing: 3%;
							line-height: 1.35;
							font-weight: 300;
							color: #194D80;
							max-width: 770px;
							text-align: center;

							@include laptops {
								font-size: 18px;
							}

							@include md { // 768
								font-size: 16px;
								text-align: left;
							}
						}
					}
				}
				.block_3 {
					display: flex;
					flex-direction: column;
					padding: 35px 0;

					.block-cnt-top {
						display: flex;

						@include md { // 768
							flex-direction: column;
							align-items: center;
						}

						.block-left {
							max-width: 581px;

							@include laptops { // 1280
								max-width: 535px;
							}

							@include md { // 768
								max-width: 100%;
								width: 100%;
								display: flex;
								flex-direction: column;
								margin-bottom: 10px;
							}

							img {
								width: 100%;
							}
						}
						.block-right {
							display: flex;
							align-items: center;
							padding-left: 25px;
							.text-block {
								p {
									padding: 7.5px 0;
									font-family: "Libre Franklin", sans-serif;
									font-weight: 300;

									@include laptops {
										font-size: 16px;
									}

									@include md { // 768
										font-size: 12px;
									}
								}
							}
						}	
					}

					.block-cnt-bottom {
						display: flex;
						justify-content: center;
						padding-top: 20px;

						@include md { // 768
							padding-top: 5px;
						}

						.text-block {
							font-family: "Libre Franklin", sans-serif;
							font-size: 20px;
							letter-spacing: 3%;
							line-height: 1.35;
							font-weight: 300;
							color: #194D80;
							max-width: 770px;
							text-align: center;

							@include laptops {
								font-size: 18px;
							}

							@include md { // 768
								font-size: 16px;
								text-align: left;
							}
						}
					}
				}
			}
		}

		.close_button {
			position: absolute;
			// top: -70px;
			// left: calc(50% - 17.5px);
			top: 10px;
			right: 20px;
			width: 35px;
			height: 35px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			@include sm {
				top: -45px;
				left: calc(50% - 17.5px);
			}

			&:before,
			&:after {
				content: "";
				display: block;
				position: absolute;
				width: 100%;
				height: 3px;
				background: black;
				border-radius: 1px;

				@include sm {
					background: white;
				}
			}

			&:before {
				transform: rotate(-45deg);
			}
			&:after {
				transform: rotate(45deg);
			}
		}
	}
}
.popup[data-popup="symptopms"] {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: none;
	justify-content: center;
	align-items: center;
	z-index: 1010;

	@media screen and (max-width: 400px) {
		align-items: center;
		padding-bottom: 20px;
	}

	&.active {
		display: flex;
	}

	.popup_close {
		width: 100%;
		height: 100%;
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		background: black;
		opacity: 0.5;
	}
	.wrapper {
		width: 1200px;
		height: calc(100% - 70px);
		border-radius: 10px;
		background: white;
		position: relative;
		z-index: 10;

		@include laptops {
			width: calc(100% - 100px);
		}
		@include md {
			width: calc(100% - 40px);
		}
		@include sm {
			width: calc(100% - 20px);
			height: calc(100% - 160px);
		}
		@media screen and (max-width: 400px) {
			height: calc(100% - 50px);
		}


		.content {
			display: block;
			width: 100%;
			height: 100%;
			overflow-y: scroll;
			border-radius: 10px;
			background: #E8F7FF;

			&::-webkit-scrollbar {
				width: 10px;				
				transition: 200ms;

				&:hover {
					width: 20px;
				}
			}
			/* Track */
			&::-webkit-scrollbar-track {
				background: #E2E7F3;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
			}
			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #194C7F;
				border-radius: 5px;
			}

			.inner_wrapper {
				padding: 50px 100px;
				background: #E8F7FF;
				
				@include laptops {
					padding: 50px;
				}
				@include lg {
					padding: 30px;
				}
				@media screen and (max-width: 375px) {
					padding: 30px 15px;
				}
			}

			.popup-title {
				font-family: "Heebo", sans-serif;
			    font-size: 32px;
			    letter-spacing: 3%;
			    line-height: 1.25;
			    font-weight: 500;
			    color: #194C7F;
			    text-align: center;
			    margin-bottom: 40px;

				@include laptops {
					font-style: 26px;
				}
				@include md {
					font-size: 22px;
					margin-bottom: 20px;
				}
				@include smallest {
					font-size: 18px;
				}
			}

			.popup-content {
				padding: 10px 0;

				.popup-big-img-box {
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 20px auto;

					@include laptops {
						align-items: center;
					}

					@include md {
						margin: 20px auto 5px;
						align-items: center;
					}

					img {
						width: auto;
						height: auto;
					}
				}
				.popup-text-box {
					font-family: "Libre Franklin", sans-serif;
					font-size: 20px;
					letter-spacing: 3%;
					line-height: 1.35;
					font-weight: 300;
					color: #194D80;
					max-width: 770px;
					text-align: center;
					display: flex;
					justify-content: center;
					margin: 0 auto;

					@include md {
						font-size: 18px;
					}

					@include md {
						font-size: 14px;
					}
				}
			}
		}

		.close_button {
			position: absolute;
			// top: -70px;
			// left: calc(50% - 17.5px);
			top: 10px;
			right: 20px;
			width: 35px;
			height: 35px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			@include sm {
				top: -45px;
				left: calc(50% - 17.5px);
			}

			&:before,
			&:after {
				content: "";
				display: block;
				position: absolute;
				width: 100%;
				height: 3px;
				background: black;
				border-radius: 1px;

				@include sm {
					background: white;
				}
			}

			&:before {
				transform: rotate(-45deg);
			}
			&:after {
				transform: rotate(45deg);
			}
		}
	}
}

// popup charts - Clinical studies
#chart1 .tick {
	font-size: 36px;
	font-weight: 200;
	font-family: "Heebo", sans-serif;
}
#chart1 .text-colors {
	font-size: 36px;
	font-weight: 200;
	font-family: "Heebo", sans-serif;
}
#chart1 .svg-text {
	font-size: 39px;
	font-weight: 200;
	font-family: "Heebo", sans-serif;
}
#chart2 .tick, #chart3 .tick, #chart4 .tick {
	font-size: 22px;
	font-weight: 200;
	font-family: "Heebo", sans-serif;
}
#chart2 .text-colors, #chart3 .text-colors, #chart4 .text-colors {
	font-size: 26px;
	font-weight: 200;
	font-family: "Heebo", sans-serif;
}
#chart2 .svg-text1, #chart3 .svg-text1, #chart4 .svg-text1 {
	font-size: 22px;
	font-weight: 200;
	font-family: "Heebo", sans-serif;
	fill: #fff;
}
#chart2 .svg-reduced-text, #chart3 .svg-reduced-text, #chart4 .svg-reduced-text {
	font-size: 24px;
	font-weight: 200;
	font-family: "Heebo", sans-serif;
	fill: #2D9CDB;
}









// colors
.lightblue {
	color: #26ACB4 !important;
}
.orange {
	color: #F49F50 !important;
}
.darkred {
	color: #7D1040 !important;
}














// animations

@keyframes antibody {
  0% {
  	transform: translate(-5px, 0);
  }
  25% {
    transform: translate(0, -5px);
  }
  50% {
    transform: translate(5px, 0);
  }
  75% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(-5px, 0);
  }
}



// brains animation
@keyframes small-circle {
	0% {
		r: 3.5;
	}
	50% {
  		r: 7;
	}
  	100% {
    	r: 3.5;
  	}
}
@keyframes middle-circle {
  	0% {
   		r: 5;
  	}
  	50% {
  		r: 9;
  	}
  	100% {
    	r: 5;
  	}
}
@keyframes bigger-circle {
  	0% {
   		r: 6;
  	}
  	50% {
  		r: 8;
  	}
  	100% {
    	r: 6;
 	}
}
@keyframes circle-big {
  	0% {
   		r: 7;
  	}
  	50% {
  		r: 11;
  	}
  	100% {
    	r: 7;
  	}
}









































