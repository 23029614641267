@import './media.scss';
@import './fonts.scss';

html, body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

html {
	min-height: 100%;
}

body {
    font-weight: 400;
    line-height: normal;
    height: 100%;
    font-size: 16px;
}

a {
	text-decoration: none;
	color: inherit;
}

button {
	border: none;
}

img, svg {
	max-width: 100%;
	max-height: 100%;
}

.container {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	max-width: 1400px;

	@include fullhd {
		max-width: 1600px;
		padding: 0 100px;
	}

	@include wsxg {
		max-width: 1270px;
		padding: 0 80px;
	}
	@include laptops {
		max-width: 1170px;
		padding: 0 40px;
	}
	@include laptops-sm {
		max-width: 970px;
		padding: 0 30px;
	}
	@include tablets {
		max-width: 100%;
		padding: 0 30px;
	}
	@include lg {
		padding: 0 25px;
	}
	@include sm {
		padding: 0 15px;
	}
}




// @media screen and (min-width: 1440px) {
// 	.container {
// 		width: 100%;
// 		height: 100%;
// 		margin: 0 auto;
// 		max-width: 1280px;
// 	}
// }
