@import './config.scss';

* {
	font-family: "Lato", sans-serif;
}

html {
	font-size: 14px;

	@include md {
		font-size: 12px;
	}
}

.mobile-hidden {
	@include md {
		display: none;
	}
}
.tab_hidden {
	@include tablets {
		display: none;
	}
}
.no_scroll {
	overflow: hidden;
}
.disabled {
	display: none;
}
.main_wrapper {
	width: 100%;
	max-width: 100vw;
	min-height: 100%;
	position: relative;
	overflow: hidden;
}

body > svg {
	display: none;
}

.t_title {
	font-weight: 600;
	font-size: 36px;
	line-height: 120%;
	letter-spacing: 0.02em;
	color: #194D80;

	@include laptops {
		font-size: 36px;
	}
	@include tablets {
		font-size: 32px;
	}
	@include lg {
		font-size: 32px;
	}
	@include xs {
		font-size: 24px;
	}
	@include smallest {
		font-size: 20px;
	}
}

.button_go {
	font-weight: 600;
	font-size: 18px;
	line-height: 125%;
	text-align: center;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	color: #26ACB4;
	border: 2px solid #26ACB4;
	border-radius: 100px;
	padding: 20px 40px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 100;
	transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
	overflow: hidden;

	@include wsxg {
		font-size: 14px;
		padding: 20px 30px;
	}
	@include laptops {
		padding: 20px;
	}
	@include lg {
		padding: 15px 20px;
	}
	@include md {
		padding: 12px 15px;
	}

	.icon {
		width: 19px;
		height: 19px;
		flex-shrink: 0;
		margin-right: 10px;
		
		svg {
			fill: #26ACB4;
			transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
		}
	}


	&-dashed {
		border-style: dashed;

		&:hover {
			border-style: solid;
		}
	}

	&__fill {
		background: #26ACB4;
		color: white;
		border: none;

		&-orange {
			background: #F49F50;
			border-style: none;
		}

		.icon {
			svg {
				fill: white;
			}				
		}
	}


	&:after {
		content: "";
		position: absolute;
		height: 0%;
		left: 50%;
		top: 50%;
		width: 150%;
		z-index: -1;
		-webkit-transition: all 0.75s ease 0s;
		-moz-transition: all 0.75s ease 0s;
		-o-transition: all 0.75s ease 0s;
		transition: all 0.75s ease 0s;
	}
	&:before {
		display: block;
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 0px;
		z-index: -1;
		content: '';
		color: #000 !important;
		background: #194D80;
		transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
	}

	&:hover {
		color: #FFF;
		text-shadow: none;
		border-color: #194D80;		

		&:after {
			height: 450%;
		}
		&:before {
			left: 0%;
			right: auto;
			width: 100%;
		}
		.icon {			
			svg {
				fill: #fff;
			}
		}
		.arrow {
			right: -5px;
		}
	}

	&-orange {
			border-color: #F49F50;
			color: #F49F50;

		&:before {
			background: #F49F50;
		}

		&:hover {
			border-color: #F49F50;
		}			
	}
}

.wrapper_brillia {
	.button_go {
			color: #11868D;
			border: 2px solid #11868D;
		&__fill {
			background: #26ACB4;
			color: #fff;
			border: none;
		}
		&:hover {
			color: #FFF;
			text-shadow: none;
			border-color: #26ACB4;
		}
		&:before {
			color: #000 !important;
			background: #11868D;
		}
	}

}

.wrapper_brilliahealth {
	.button_go {
			color: #7D1040;
			border: 2px solid #7D1040;
		&__fill {
			background: #194D80;
			color: #fff;
			border: none;
		}
		&:hover {
			color: #FFF;
			text-shadow: none;
			border-color: #7D1040;
		}
		&:before {
			color: #000 !important;
			background: #0F3861;
		}
	}

}

.wrapper_brilliahealth {
	.button-red {
		&:before {
			color: #000 !important;
			background: #7D1040;
		}
	}
}

.props_list {
	li {
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		letter-spacing: 0.01em;
		color: #194C7F;
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		
		@include tablets {
			font-size: 16px;
			margin-bottom: 10px;
		}

		.check {
			width: 20px;
			height: 20px;
			background: #F49F50;
			flex-shrink: 0;
			border-radius: 50%;
			margin-right: 10px;
			display: flex;
			justify-content: center;
			align-items: center;

			&:before {
				content: "";
				display: block;
				width: 12px;
				height: 8px;
				background: url('../../images/check.png') no-repeat center;
				background-size: contain;
			}
		}						
	}
}


.wrapper_brillia {
	.props_list {
		li{
			&:last-child {
				margin-left: 35px;
				@include laptops-sm {
    			margin-left: 0;
    			
    			}
			}	
			.check {
				background: #26ACB4;
			}
		}
	}
}

.wrapper_brilliahealth {
	.props_list {
		li{
			&:last-child {
				margin-left: 35px;
				@include laptops-sm {
    			margin-left: 0;
    			
    			}
			}
			.check {
				background: #F49F50;
			}
		}
	}
}

.breadcrumbs {
	padding: 130px 0 20px;

	@include smallest {
		padding: 115px 0 20px;
	}

	.wrapper {
		display: flex;
		align-items: center;

		a, span {
			font-size: 16px;
			line-height: 19px;
			letter-spacing: 0.01em;
			text-decoration-line: underline;
			color: #7791AA;
			transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1);

			&:last-child {
				text-decoration: none;
				pointer-events: none;
			}

			&:hover {
				color: #20BDC7;
			}
		}
		.separator {
			margin: 0 5px;
			text-decoration: none;
		}
	}
}


.header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 1000;
	transition: all .2s;
	height: 80px;
	z-index: 1000;

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 1010;

		.logo {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 1011;
			height: 40px;

			img:nth-child(1) {
				margin-right: 20px;
			}
			img:nth-child(2) {
				@include lg {
					display: none;
				}
			}
		}
		.nav_list {
			display: flex;
			align-items: center;

			@include md {
				display: none;
				z-index: 1000;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				padding: 100px 0 20px;
				flex-direction: column;
				justify-content: center;
				background: #ECF6F7;
			}

			.nav_item {
				margin-right: 30px;
				position: relative;

				@include md {
					margin-right: 0;
					margin-bottom: 25px;
				}

				&:last-child {
					margin-right: 0;
				}

				& > a {
					font-weight: 600;
					font-size: 16px;
					line-height: 20px;
					letter-spacing: 0.01em;
					text-transform: uppercase;
					color: #194D80;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1);

					@include md {
						font-size: 18px;
					}

					.arrow {
						width: 15px;
						height: 15px;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 2px solid #194D80;
						border-radius: 50%;
						margin-left: 5px;

						svg {
							width: 9px;
							height: 5px;
							fill: #194D80;
						}
					}
				}
				.popup_list {
					width: 190px;
					position: absolute;
					top: calc(100% + 15px);
					left: 50%;
					transform: translate(-50%);
					background: #ECF6F6;
					opacity: 0;
					pointer-events: none;

					@include md {
						position: relative;
						opacity: 1;
						pointer-events: all;
						display: none;
					}

					a {
						padding: 10px 20px;
						display: flex;
						font-size: 14px;
						text-align: center;
						color: #194D80;
						display: flex;
						align-items: center;
						justify-content: center;
						text-transform: uppercase;
						width: 190px;
						transition: 0.1s;
						transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1);

						@include md {
							padding: 0;
							font-size: 16px;
							margin-top: 15px;
						}

						&:hover {
							background: #20BDC7;
							color: white;

							@include md {
								background: transparent;
								color: #194D80;
							}
						}
					}
					&:before {
						content: "";
						display: block;
						width: 100%;
						height: 15px;
						position: absolute;
						left: 0;
						top: -15px;
					}
				}

				&:hover {
					& > a {
						color: #20BDC7;

						.arrow {
							border-color: #20BDC7;

							svg {
								fill: #20BDC7;
							}
						}
					}
					.popup_list {
						opacity: 1;
						pointer-events: all;
					}
				}

				&.active {
					.popup_list {
						@include md {
							display: block;
						}
					}
				}
			}
		}
		.nav_burger {
			display: none;
			width: 30px;
			height: 19px;
			position: relative;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-left: 25px;
			margin-right: 30px;
			z-index: 1010;

			@include md {
				display: flex;
			}
			@include xs {
				margin-right: 15px;
			}

			span {
				width: 100%;
				height: 3px;
				position: absolute;
				background: #194D80;

				&:nth-child(1) {
					top: 0;
				}
				&:nth-child(2) {
					// top: 0;
				}
				&:nth-child(3) {
					bottom: 0;
				}
			}

			&.active {
				span {
					&:nth-child(2) {
						display: none;
					}

					&:nth-child(1) {
						top: auto;
						transform: rotate(-45deg);
					}
					&:nth-child(3) {
						bottom: auto;
						transform: rotate(45deg);
					}
				}
			}
		}
	}

	.header_background {
		display: block;
		width: 100%;
		height: 100%;
		transition: all .3s;
		border-bottom-left-radius: 50px;
		background: #ECF6F7;
		top: 0;
		left: 54%;
		position: absolute;
		z-index: 1000;

		@include wsxg {
			left: 50%;
		}
		@include laptops {
			left: 47%;
		}
		@include laptops-sm {
			left: 39%;
		}
		@include tablets {
			left: 35%;
		}
		@include lg {
			left: calc(100% - 650px);
		}
		@include md {
			border-radius: 0;
			left: 0;
		}
	}

	&.scroll,
	&:hover {
		.header_background {
			border-radius: 0;
			left: 0;
		}
	}

	&.active {
		.wrapper {
			.nav_list {
				display: flex;
			}
			.nav_burger {
				background: none;

				span {
					&:nth-child(2) {
						display: none;
					}

					&:nth-child(1) {
						top: auto;
						transform: rotate(-45deg);
					}
					&:nth-child(3) {
						bottom: auto;
						transform: rotate(45deg);
					}
				}
			}
		}
	}
}

.footer {
	background: #ECF6F6;
	padding: 75px 0;

	@include laptops {
		padding: 50px 0 20px;
	}

	.wrapper {
		display: flex;

		@include laptops {
			flex-direction: column;
		}

		.menu {
			width: 70%;
			display: flex;
			justify-content: flex-start;

			@include laptops {
				width: 100%;
				margin-bottom: 30px;
			}
			@include md {
				display: none;
			}

			.col {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin-right: 65px;

				@include lg {
					margin-right: 20px;

					&:last-child {
						margin-right: 0;
					}
				}

				.row {
					font-size: 14px;
					line-height: 16px;
					letter-spacing: 0.01em;
					color: #7791AA;
					margin-bottom: 10px;
					transition: all 0.25s cubic-bezier(0.42, 0, 0.58, 1);
					cursor: pointer;

					&:last-child {
						margin-bottom: 0;
					}

					&.main {
						font-weight: 600;
						font-size: 20px;
						line-height: 25px;
						letter-spacing: 0.01em;
						text-transform: uppercase;
						color: #194D80;
						margin-bottom: 15px;

						@include wsxg {
							font-size: 18px;
						}
					}

					&:hover {
						color: #20BDC7;
					}
				}
			}
		}
		.general {
			width: 30%;

			@include laptops {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			@include md {
				flex-direction: column;
				align-items: center;
			}

			.logo {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 25px;
				height: 40px;

				img:first-child {
					margin-right: 20px;
					flex-shrink: 0;

					@include xs {
						width: 90px;
						margin-bottom: 0;
						margin-right: 10px;
					}
				}
				img:last-child {
					@include xs {
						width: 190px;
					}
				}

				@include md {
					width: 100%;
					margin-bottom: 25px;
				}
			}
			.text {
				font-size: 16px;
				line-height: 120%;
				letter-spacing: 0.02em;
				color: #194D80;

				@include laptops {
					text-align: right;
					width: 50%;
				}
				@include md {
					text-align: center;
					width: 450px;
					max-width: 100%;
				}
			}
		}
	}
}