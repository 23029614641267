@font-face {
	font-display: swap;
	font-family: "Signpainter";
	src: url("../fonts/Signpainter.otf");
	// font-style: normal; 
	// font-weight: 300;
}

@font-face {
	font-display: swap;
	font-family: "Lato";
	src: url("../fonts/Lato/Lato-Light.ttf");
	font-style: normal; 
	font-weight: 300;
}
@font-face {
	font-display: swap;
	font-family: "Lato";
	src: url("../fonts/Lato/Lato-LightItalic.ttf");
	font-style: italic; 
	font-weight: 300;
}
@font-face {
	font-display: swap;
	font-family: "Lato";
	src: url("../fonts/Lato/Lato-Regular.ttf");
	font-style: normal;
	font-weight: 400;
}
@font-face {
	font-display: swap;
	font-family: "Lato";
	src: url("../fonts/Lato/Lato-Regular.ttf");
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-display: swap;
	font-family: "Lato";
	src: url("../fonts/Lato/Lato-Medium.ttf");
	font-style: normal; 
	font-weight: 500;
}
@font-face {
	font-display: swap;
	font-family: "Lato";
	src: url("../fonts/Lato/Lato-MediumItalic.ttf");
	font-style: italic; 
	font-weight: 500;
}


@font-face {
	font-display: swap;
	font-family: "Gilroy";
	src: url("../fonts/Gilroy/Gilroy-SemiBold.ttf");
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-display: swap;
	font-family: "Gilroy";
	src: url("../fonts/Gilroy/Gilroy-Light.ttf");
	font-style: normal;
	font-weight: 300;
}

// @font-face {
// 	font-display: swap;
// 	font-family: "Lato";
// 	src: url("../fonts/Lato/Lato-Regular.ttf");
// 	font-style: normal;
// 	font-weight: 400;
// }
// @font-face {
// 	font-display: swap;
// 	font-family: "Lato";
// 	src: url("../fonts/Lato/Lato-Regular.ttf");
// 	font-style: italic;
// 	font-weight: 400;
// }





